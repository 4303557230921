<template>
	<div class="opacity-page">
		<div class="header-filter">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('admin_users.search-by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>

		<template v-if="loadedList">

			<div class="total-entries row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="count-entries">{{ $t('admin_users.filters.total_entries') }}<span class="number">{{ total_users }}</span></div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="data-list list-users-report" v-if="users.length">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-user" v-if="$resize && $mq.above(501)"></div>
						<div class="column-filter column-master_inst_id">
							<div class="text">{{ $t('admin_users.user_id') }}</div>
						</div>
						<div class="column-filter column-name">
							<div class="text">{{ $t('admin_users.name') }}</div>
						</div>
						<div class="column-filter column-entrepreneur">
							<div class="text">{{ $t('admin_users.entrepreneur_name') }}</div>
						</div>
						<div class="column-filter column-objectives">
							<div class="text">{{ $t('admin_users.objectives') }}</div>
						</div>
						<div class="column-filter column-procedures">
							<div class="text">{{ $t('admin_users.procedures') }}</div>
						</div>
						<div class="column-filter column-organigram">
							<div class="text">{{ $t('admin_users.organigram') }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="user in users">
					<div class="row-space-tbf row-list-item" :key="user.id">
						<div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-simple-text column-master_inst_id">
								<div class="text">{{ user.id }}</div>
							</div>
							<div class="column-name-desc column-name">
								<div class="name">{{ user.name }}</div>
								<div class="description">{{ user.email }}</div>
								<div class="description">{{ user.phone}}</div>
							</div>
							<div class="column-name-desc column-entrepreneur">
								<div class="name">{{ user.entrepreneur ? user.entrepreneur.name : '' }}</div>
								<div class="description">{{ user.entrepreneur ? user.entrepreneur.email : '' }}</div>
								<div class="description">{{ user.entrepreneur ? user.entrepreneur.phone : '' }}</div>
							</div>
							<div class="column-name-desc column-objectives" v-if="user.objective">
								<div class="name">{{ user.objective.company_name }}</div>
								<div class="description" v-if="user.objective.cui != null">{{ $t('admin_users.cui')  }} {{ user.objective.cui }}</div>
							</div>
							<div class="column-name-desc column-objectives" v-else></div>

							<div class="column-name-desc column-procedures" v-if="user.procedure">
								<div class="name">{{ user.procedure.company_name }}</div>
								<div class="description" v-if="user.procedure.cui != null" >{{ $t('admin_users.cui')  }} {{ user.procedure.cui }}</div>
							</div>
							<div class="column-name-desc column-procedures" v-else></div>

							<div class="column-name-desc column-organigram" v-if="user.organigram">
								<div class="name">{{ user.organigram.company_name }}</div>
								<div class="description" v-if="user.organigram.cui != null">{{ $t('admin_users.cui')  }} {{ user.organigram.cui }}</div>
							</div>
							<div class="column-name-desc column-organigram" v-else></div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="title">{{ $t('admin_users.empty_title')}}</div>
					<div class="description" v-html="$t('admin_users.empty_users_text')"></div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>
		
		<infinite-loading :identifier="infiniteId" @infinite="infiniteUsersHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
    import IconSearch from '../Icons/Search'
    import IconArrow from '../Icons/Arrow'
    import IconTrash from '../Icons/Trash'
    import IconUser from '../Icons/User'
    import IconPeople from '../Icons/People'
	import IconSettings from '../Icons/Settings'
	import IconClose from '../Icons/Close'
    import LoaderItemsList from '../PagesLoaders/ItemsList'
	import InfiniteLoading from 'vue-infinite-loading'

    export default {
    	components: {
            IconSearch,
            IconArrow,
            IconTrash,
            IconUser,
            IconPeople,
			IconSettings,
			IconClose,
           	LoaderItemsList, 
			InfiniteLoading
        },
        data() {
            return {
            	loaded: false,
				loadedList: false,
            	search_filter: '',
            	users: [],
            	sortBy: '',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1,
				last_updated: '',
				total_users: '',
				queryFiltersCount: 0,
            	extendFilters: true,
            }
        },
        async mounted(){
        	var refreshIntervalApps = setInterval(() => {
        		if(this.$store.state.packages.list.length){
        			clearInterval(refreshIntervalApps);
        			setTimeout(() => {
        				this.filterPackagesObjectives = this.$store.getters['packages/getBySoft']('objective').packages.map(el => { return el.unique_name })
        				this.filterPackagesProcedures = this.$store.getters['packages/getBySoft']('procedure').packages.map(el => { return el.unique_name })
        				this.filterPackagesOrganigram = this.$store.getters['packages/getBySoft']('organigram').packages.map(el => { return el.unique_name })
        			}, 200)
        		}
        	}, 200);

			this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)


			setTimeout(() => {
				var title = this.$t('navbar.users_list');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshUsersIndex', () => {
				this.searchFilterFunct()
			})

			this.$root.$on('syncMiniAdmin', () => {
				axios.get('/overall')
				.then(() => {
					this.searchFilterFunct()
				})
			})

        },
        watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
        methods: {
			infiniteUsersHandler($state){
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

        		axios.get('/users', { params: paramsCall} )
        		.then(({data}) => {
        			this.last_updated = data.data.last_updated
        			this.total_users = data.data.total_users

        			if(data.data.users.length){
        				this.page++
        				this.users.push(...data.data.users)
						setTimeout(() => { $state.loaded() }, 200)
							if(data.data.users.length < 20){
								$state.complete()
							}
					}else{
						$state.complete()
					}
					this.loadedList = true
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
        	},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);

				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
				});
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.users = []

				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			},
			syncList(){
				var buttonName = `buttonSync`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.get('/overall')
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('admin_users.sync')
							btnSubmit.disabled = false

							this.searchFilterFunct()
						}, 300)
					})
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('admin_users.sync')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
			},
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			}
        }
    }

	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
    .last-update-text{
    	align-self: center;
    	margin-right: 20px;
    	font-size: 13px;
    	.date{
    		margin-top: 3px;
    		text-transform: capitalize;
    		font-weight: 500;
    	}
    }

    .total-entries{
    	padding: 15px 0;
    	.count-entries{
    		font-size: 14px;
    		.number{
    			font-weight: 500;
    		}
    	}
    }

    .mt-second-filter{
    	margin-top: 15px;
    }

    .list-users-report{
    	.row-list-item{
    		&:hover{
    			.content{
    				.column-name{
    					.name{
    						font-weight: 700;
    					}
    				}
    			}
    		}
    	}
    	.column-name{
    		flex: 1 1 auto;
    		&.link{
    			cursor: pointer;
    		}
    	}
    	.column-master_inst_id{
    		flex: 0 0 80px;
    	}
    	.column-entrepreneur{
    		flex: 0 0 300px;
    	}
    	.column-objectives,
    	.column-procedures,
    	.column-organigram{
    		flex: 0 0 200px;

    		.description{
	    		&.status-active{
	    			color: #03ae58 !important;
	    		}
	    		&.status-grace_period{
	    			color: $yellow !important;
	    		}
	    		&.status-unpaid{
	    			color: $red !important;
	    		}
	    		&.status-new{
	    			color: $blue !important;
	    		}
    		}
    	}
    	.column-created_at{
    		flex: 0 0 100px;
    	}
    }
</style>